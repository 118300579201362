<template lang="pug">
.header(:class="fixed ? 'fixed' : ''" style="margin-top: max(8px, 8px + env(safe-area-inset-top))")
    .row.items-center.justify-between.q-px-md.no-wrap(
        :class="class"
        :style="`background-color: ${bgColor}`")
        router-link.link(:to="city ? `/${city.slug}/` : '/'" title="Главная")
            logo-mobile.q-mr-sm
        q-icon(
            name="etabl:side-contacts"
            dense
            size="20px"
            color="positive"
            @click="goCall"
        )
        search-button(:searchPlaceholder="searchPlaceholder")
        side-menu-mobile
    .row.items-center.q-pb-xs.q-px-md.no-wrap(:class="hasSlots || catalogCategory ? 'q-mt-md' : ''")
        slot(name="leftIcon")
        slot(name="title")
        slot(name="share")
        slot(name="qr")
        slot(name="sort")
        slot(name="share")
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LogoMobile from 'components/Logo/LogoMobile.vue';
import SearchButton from 'components/SearchButton/SearchButton.vue';
import SideMenuMobile from 'components/SideMenu/SideMenuMobile';

export default {
    name: 'HeaderMobile',
    props: {
        class: {
            type: String,
        },
        bgColor: {
            type: String,
        },
        showHumburg: {
            type: Boolean,
            default: () => true,
        },
        showSearchButton: {
            type: Boolean,
            default: () => true,
        },
        searchPlaceholder: {
            type: String,
        },
        catalogCategory: {
            type: Boolean,
            default: () => false,
        },
        fixed: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        LogoMobile,
        SearchButton,
        SideMenuMobile,
    },

    setup(_, { slots }) {
        const store = useStore();

        const hasSlots = computed(() => !!(slots.leftIcon || slots.title || slots.share || slots.qr || slots.sort ));

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const goCall = () => {
            if (process.env.CLIENT) {
                window.location.replace('tel:+78002005400');
            }
        };


        return {
            hasSlots,
            city,
            goCall,
        };
    },
};
</script>

<style lang="scss" scoped>
.header {
    margin-top: Max(8px, calc(8px + constant(safe-area-inset-top)));
    margin-top: Max(8px, calc(8px + env(safe-area-inset-top)));
}

.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: Max(8px, calc(8px + constant(safe-area-inset-top)));
    padding-top: Max(8px, calc(8px + env(safe-area-inset-top)));
    margin: 0;
    z-index: 9999;
}
</style>
