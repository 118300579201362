<template lang="pug">
q-icon.q-ml-md(
    name="etabl:humburg"
    size="24px"
    color="positive"
    @click="showMenu = true")
q-dialog(
    full-width
    full-height
    :maximized="true"
    class="menu-dialog"
    v-model="showMenu"
    transition-show="slide-left"
    transition-hide="slide-right")
    .full-width.b-radius-0.q-py-md.overflow-x-scroll.relative-position.menu-container
        .page-top-area.fixed-top
        .column.q-px-md
            .row.justify-between.items-center.q-mt-xs
                q-avatar.cursor-pointer(
                    square
                    style="width: 84px; height: 46px;")
                    img(src="logo_text.svg")
                q-icon(
                    size="24px"
                    color="positive"
                    name="etabl:cross"
                    @click="showMenu = false")
            city-form-mobile
            template(v-for="item in items")
                router-link.column.q-mt-md(
                    v-if="item.type == 'link'"
                    style="text-decoration: none;"
                    :to="city ? `/${city.slug}${item.link}` : item.link"
                    :title="`${item.title}`"
                    v-close-popup)
                        .row.relative-position
                            q-icon.q-ml-xs.q-mr-md(
                                :name="`etabl:side-${item.icon}`"
                                color="positive"
                                size="24px")
                            .font-14.text-primary {{ item.title }}
                q-expansion-item.q-mt-md(
                    v-else
                    dense
                    header-class="text-primary q-pa-none"
                    header-style="min-height: 0"
                    :expand-icon-class="expandShow ? 'text-positive' : ''"
                    @show="expandShow = true"
                    @hide="expandShow = false"
                    duration="500")
                    template(v-slot:header)
                        q-item-section
                            .row
                                q-icon.q-ml-xs.q-mr-md(
                                    :name="`etabl:side-${item.icon}`"
                                    color="positive"
                                    size="24px"
                                )
                                .font-14.text-primary {{ item.title }}
                    template(v-for="item in item.nested")
                        router-link.column.q-mt-md(
                            v-if="item.type == 'link'"
                            style="text-decoration: none;"
                            :to="city ? `/${city.slug}${item.link}` : item.link"
                            :title="`${item.title}`"
                            v-close-popup)
                                .row.relative-position
                                    q-icon.q-ml-xs.q-mr-md(
                                        :name="`etabl:side-${item.icon}`"
                                        color="positive"
                                        size="24px")
                                    .font-14.text-primary {{ item.title }}
                        a.column.q-mt-md(
                            :href="item.link" 
                            target="_blank" 
                            rel="nofollow" 
                            @click="goToBot" 
                            v-if="item.type == 'outer-link'"
                            style="text-decoration: none;"
                            v-close-popup)
                            .row.relative-position
                                q-icon.q-ml-xs.q-mr-md(
                                    :name="`etabl:side-${item.icon}`"
                                    color="positive"
                                    size="24px")
                                .font-14.text-primary {{ item.title }}
                    
            div(v-if="!app")
                .text-positive.text-weight-medium.q-mt-md
                    | Установите наше приложение
                .flex.justify-between.q-px-lg.q-mb-sm
                    a.q-py-md(href="https://play.google.com/store/apps/details?id=artes.tabletochka" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:google-play"
                            size="lg"
                            color="positive")
                    a.q-py-md(href="https://apps.apple.com/ru/app/etabl/id1542449495" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:app-store"
                            size="lg"
                            color="positive")
                    a.q-py-md(href="https://appgallery.huawei.com/app/C107603961" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:app-gellary"
                            size="lg"
                            color="positive")
                    a.q-py-md(href="https://apps.rustore.ru/app/artes.tabletochka" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:ru-store"
                            size="lg"
                            color="positive")
            
            
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BaseBtn from 'components/Base/BaseBtn.vue';
import CityFormMobile from 'components/CityForm/CityFormMobile.vue';

export default {
    name: 'Humburg',
    components: {
        CityFormMobile,
        BaseBtn,
    },
    setup() {
        const showMenu = ref(false);
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const q = useQuasar();

        const expandShow = ref(false);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const items = computed(() => [
            {
                icon: 'mobile_catalog',
                title: 'Каталог',
                type: 'link',
                link: '/catalog-list-mobile',
            },{
                icon: 'locatons',
                title: 'Адреса аптек',
                type: 'link',
                link: '/drugstores',
            },{
                icon: 'mobile_profile',
                title: 'Покупателю',
                type: 'button',
                nested: [
                    {
                        icon: 'tg',
                        title: 'Отследить заказ',
                        type: 'outer-link',
                        link: 'https://t.me/eTabl_eBot',
                    }, {
                        icon: 'how-order',
                        title: 'Как заказать?',
                        type: 'link',
                        link: '/how-to-order',
                    }, {
                        icon: 'delivery',
                        title: 'Доставка',
                        type: 'link',
                        link: '/shipping',
                    }, {
                        icon: 'payment',
                        title: 'Способы оплаты',
                        type: 'link',
                        link: '/shipping-and-payment',
                    }, {
                        icon: 'faq',
                        title: 'Вопрос-ответ',
                        type: 'link',
                        link: '/faq',
                    }, {
                        icon: 'loyalty',
                        title: 'Программа лояльности',
                        type: 'link',
                        link: '/loyalty',
                    },
                ],
            }, {    
                icon: 'sale',
                title: 'Акции',
                type: 'link',
                link: '/marketing-list',
            }, {
                icon: 'articles-clear',
                title: 'Статьи',
                type: 'link',
                link: '/articles',
            }, {
                icon: 'partners',
                title: 'Проекты',
                type: 'link',
                link: '/projects',
            }, {
                icon: 'policy',
                title: 'O сервисе',
                type: 'button',
                nested: [
                    {
                        icon: 'agreement',
                        title: 'Пользовательское соглашение',
                        type: 'link',
                        link: '/agreement',
                    }, {
                        icon: 'policy',
                        title: 'Политика конфиденциальности',
                        type: 'link',
                        link: '/policy',
                    }, {
                        icon: 'policy',
                        title: 'Обработка персональных данных',
                        type: 'link',
                        link: '/person-data',
                    }, {
                        icon: 'paper',
                        title: 'Продажа дистанционным способом',
                        type: 'link',
                        link: '/selling-remotely',
                    },
                ],
            }, {  
                icon: 'contacts',
                title: 'Контакты',
                type: 'link',
                link: '/contacts',
            }, {
                icon: 'vacancies',
                title: 'Вакансии',
                type: 'link',
                link: '/vacancies',
            }, 
        ]);

        const goStore = async () => {
            close();
            router.push('/redirect-to-store');
        };

        const app = computed(() => q.platform.is.cordova);

        const goToBot = () => {
            if (process.env.CLIENT) {
                // window._paq.push(['trackGoal', 19]);
            };
        };

        return {
            showMenu,
            items,
            goStore,
            app,
            city,
            expandShow,
            goToBot,
        };
    },
};
</script>

<style lang="scss" scoped>
  
:deep(.menu-dialog) {
    .q-dialog__inner {
        width: 100%;
    }
}

.menu-container {
    background-color: #fbfafa;
    padding-top: Max(8px, calc(8px + constant(safe-area-inset-top))) !important;
    padding-top: Max(8px, calc(8px + env(safe-area-inset-top))) !important;
}

.q-btn-group {
    width: 100%;
    height: 40px;
    border: 1px solid #0CA551;
}

.app-button {
    &__main {
        width: 80%;
    }
}

</style>
