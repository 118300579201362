<template lang="pug">
.col-6.row.no-wrap.search-block.items-center.q-px-md.bg-secondary(
    style="height: 40px;"
    @click="showSearchMobile = true")
    q-icon(
        name="etabl:search"
        size="20px"
        color="positive")
    .font-14.q-ml-sm.search-text(:class="searchPlaceholder ? 'text-primary' : 'text-positive'")
        .text-ellipsis {{ searchPlaceholder || 'Найти' }}
search-mobile(
    v-if="showSearchMobile"
    :value="searchPlaceholder"
    @hide="showSearchMobile = false")
</template>

<script>
import { ref } from 'vue';
import SearchMobile from 'components/Search/SearchMobile.vue';

export default {
    name: 'SearchButton',
    props: {
        searchPlaceholder: {
            type: String,
        },
    },
    components: {
        SearchMobile,
    },
    setup() {
        const showSearchMobile = ref(false);
        
        return {
            showSearchMobile,
        };
    },
};
</script>

<style lang="scss" scoped>
.search-block {
    border-radius: $mobile-border-radius;
    height: 40px;
}

.search-text {
    width: 80%;
}
</style>
