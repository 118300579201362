<template lang="pug">
.fixed-top-left.full-width.full-height.bg-secondary.relative-position.overflow-y-scroll(
    ref="blockResults"
    style="z-index: 999;")
    .row.items-center.q-py-xs.q-px-md.q-pt-md.fixed-top-left.full-width.bg-secondary.search-mobile(style="z-index: 99;")
        q-input.border-none.relative-position.default-mobile-field.search-field(
            ref="field"
            dense
            outlined
            autofocus
            color="positive"
            autocomplete="off"
            label-color="accent"
            bg-color="secondary"
            placeholder="Название, бренд или вещество"
            :debounce="0"
            @keydown.enter="goSearch(query)"
            v-model="query")
            template(v-slot:prepend)
                q-icon(
                    size="20px"
                    color="positive"
                    name="etabl:search")
            template(v-slot:append)
                q-icon(
                    v-if="query"
                    size="18px"
                    color="grey-12"
                    name="etabl:cross"
                    @click="clearSearch")
        .text-positive.font-14.q-pl-md.text-weight-medium(@click="$emit('hide')") Отмена
    .absolute-top-left.full-width.full-height.row.items-center.justify-center.fu(v-if="loading")
        q-spinner(
            :thickness="5"
            size="md"
            color="positive")
    template(v-else)
        .q-pl-md.search-result
            template(v-for="suggest in suggestions")
                .row.no-wrap.items-start(@click="goToSuggest(suggest)")
                    .col-1
                        q-icon.q-mt-xs(
                            name="etabl:search"
                            size="18px"
                            color="positive")
                    .col-11.q-pr-md
                        .row.no-wrap.items-center.justify-between
                            .font-14.text-primary.text-ellipsis {{ suggest }}
                            q-icon(
                                name='etabl:arrow_next'
                                color="grey-12"
                                size="8px")
                        q-separator.full-width(spaced="8px")
            template(v-if="products.length")
                .font-14.text-grey-12.q-mt-sm.q-mb-md Товары
                .row.no-wrap.q-mt-sm.product.q-pr-md(
                    v-for="(product,index) in products"
                    @click="goToProduct(product)"
                    :style="{'margin-bottom': index === products.length - 1 ? '100px' : ''}"
                )
                    .col-2.q-pt-xs.q-pr-xs.q-pb-xs
                        img.product__img(:src="product.previewImage")
                    .col-10.column.justify-between
                        .row.justify-between.full-width.q-pr-md.q-pb-sm
                            .row.column.font-12.text-primary.q-pl-lg
                                .text-weight-bold.text-ellipsis.custom-sale
                                    .q-px-sm.q-py-xs.text-white.font-12.text-weight-regular.b-radius-16.q-mb-xs.line-height-16(
                                        v-if="product.priceCrossed ||product.titleShort ||  product?.advertismentTitles?.length"
                                        :class="sliderProductDay || (product?.advertismentTitles?.length && product?.advertismentTitles[0]) === 'Товар дня' ? 'bg-blue-10' : 'bg-negative'"
                                    )
                                        div(v-if="product?.titleShort") {{product.titleShort}}
                                        div(v-else-if="product?.advertismentTitles?.length" ) {{product?.advertismentTitles[0]}}
                                        div(v-else ) Акция

                                .text-weight-regular.text-ellipsis.font-14
                                    //| {{ product.name }}
                                    span.block#name(style="white-space: pre-line;") {{formattedName(`${product.name} ${product.subtitleShort}`)}}
                                        base-tooltip(:title="product.name")
                                //.text-weight-regular.text-ellipsis {{ product.subtitleShort }}
                                .text-weight-regular.text-ellipsis.font-14.text-grey-9 {{ product.manufacture }}
                            //.col-3.text-primary.text-weight-medium.q-ma-none.justify-end.items-center
                        .row.items-center.justify-end(v-if="product.price")
                            .text-weight-bold.font-13.text-grey-9(
                                v-if="product.priceCrossed && product.priceCrossed !== product.price"
                                style="text-decoration: line-through;"
                            )
                                | {{ new Intl.NumberFormat('ru-RU').format(product.priceCrossed) }}
                                span &nbsp₽
                            .text-right.text-weight-bold.font-14(v-if="product.price")
                                | &nbsp{{ new Intl.NumberFormat('ru-RU').format(product.price) }}
                                span &nbsp₽
                        .text-right.text-weight-bold.font-14.text-grey-12(v-else)
                            | нет в наличии
                        q-separator.full-width.q-mt-sm

</template>

<script>
import { ref, watch, nextTick, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'SearchMobile',
    emits: ['hide'],
    props: {
        value: {
            type: String,
        },
    },
    setup(props, { emit }) {
        // объект стора
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const field = ref();
        const loading = ref(false);
        const blockResults = ref();
        const query = ref(props.value);

        // поисковые подсказки
        const suggestions = ref([]);

        // список продуктов
        const products = ref([]);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        watch(query, (value) => {
            search(value);
        });

        // очистка поисковой строки
        const clearSearch = () => {
            query.value = '';

            nextTick(() => {
                field.value.focus();
            });
        };

        // функция поиска
        const search = async (aQuery) => {
            loading.value = true;

            suggestions.value = [];
            products.value = [];

            if (aQuery) {
                // загрузка подсказок
                // suggestions.value = (await store.dispatch('search/QUERY_SUGGESTION', { query: aQuery })).suggestions;

                // if (aQuery.length > 2) {
                //     // загрузка продуктов
                //     products.value = (await store.dispatch('search/SEARCH', { query: aQuery })).results;
                // }

                let result = await store.dispatch('search/NEW_SUGGESTION', { query: aQuery });

                suggestions.value = result.suggest;

                if (aQuery.length > 2)
                    products.value = result.products;
            }
            loading.value = false;
        };

        onMounted(async () => {
            blockResults.value.addEventListener('scroll', () => {
                field.value.blur();
            });

            if (query.value)
                await search(query.value);
        });

        const scroll = () => {
        };

        const goToProduct = (product) => {
            if (city.value) {
                router.push(`/${city.value.slug}/product/${product.url}`);
            } else {
                router.push(`/product/${product.url}`);
            }

            if (route.name == 'Product') {
                emit('hide');
            } else {
                return null;
            }
            try {
                window.appMetrica.reportEvent('Переход из подсказки поиска в карточку товара', { 'Наименование товара': `${product.name} + ${product.subtitle.short}` });
            } catch {
            }
        };

        const goToSuggest = (suggest) => {
            try {
                window.appMetrica.reportEvent('Переход по подсказке поиска в результаты поиска', { 'Подсказка': `${suggest}` });
            } catch {
            }

            if (city.value) {
                router.push(`/${city.value.slug}/search?query=${suggest}&limit=20`);
            } else {
                router.push(`/search?query=${suggest}&limit=20`);
            }

            if (route.name == 'search') {
                emit('hide');
            } else {
                return null;
            }
        };

        const translates = {
            'a': 'ф',
            'b': 'и',
            'c': 'с',
            'd': 'в',
            'e': 'у',
            'f': 'а',
            'g': 'п',
            'h': 'р',
            'i': 'ш',
            'j': 'о',
            'k': 'л',
            'l': 'д',
            'n': 'т',
            'm': 'ь',
            'o': 'щ',
            'p': 'з',
            'q': 'й',
            'r': 'к',
            's': 'ы',
            't': 'е',
            'u': 'г',
            'v': 'м',
            'w': 'ц',
            'x': 'ч',
            'y': 'н',
            'z': 'я',
            ',': 'б',
            ';': 'ж',
            '[': 'х',
            ']': 'ъ',
            '\'': 'э',
            '.': 'ю',
            '`': 'ё',
            'A': 'Ф',
            'B': 'И',
            'C': 'С',
            'D': 'В',
            'E': 'У',
            'F': 'А',
            'G': 'П',
            'H': 'Р',
            'I': 'Ш',
            'J': 'О',
            'K': 'Л',
            'L': 'Д',
            'N': 'Т',
            'M': 'Ь',
            'O': 'Щ',
            'P': 'З',
            'Q': 'Й',
            'R': 'К',
            'S': 'Ы',
            'T': 'Е',
            'U': 'Г',
            'V': 'М',
            'W': 'Ц',
            'X': 'Ч',
            'Y': 'Н',
            'Z': 'Я',
            '<': 'Б',
            ':': 'Ж',
            '{': 'Х',
            '}': 'Ъ',
        };

        const goSearch = (query) => {
            try {
                window.appMetrica.reportEvent('Переход в результаты поиска', { 'Подсказка': `${query}` });
            } catch {
            }

            let arrString = query.split('');

            let newArr = [];

            arrString.forEach((item) => {
                if (translates[item]) {
                    newArr.push(translates[item]);
                } else {
                    newArr.push(item);
                }
            });

            let finalString = newArr.join('');

            if (city.value) {
                router.push(`/${city.value.slug}/search?query=${finalString}&noTerm=true&limit=20`);
            } else {
                router.push(`/search?query=${finalString}&noTerm=true&limit=20`);
            }

            if (route.name == 'search') {
                emit('hide');
            } else {
                return null;
            }
        };
        const formattedName = (text) => {
            if (!text) return '';

            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                if (lastLine && lastLine.length + word.length + 1 > 20) {
                    acc.push(word);
                } else {
                    if (lastLine) {
                        acc[acc.length - 1] += ' ' + word;
                    } else {
                        acc.push(word);
                    }
                }
                return acc;
            }, []);

            if (lines.length > 2) {
                lines.length = 2;
                lines[1] += '...';
            }

            return lines.join(' ');
        };

        return {
            goToProduct,
            goToSuggest,
            goSearch,
            field,
            route,
            query,
            router,
            search,
            scroll,
            loading,
            products,
            suggestions,
            clearSearch,
            blockResults,
            city,
            translates,
            formattedName,
        };
    },
};
</script>

<style lang="scss" scoped>
span.block {
    &#name {
        display: block !important;
        line-height: 22px;
        height: 44px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 147px;
        text-overflow: ellipsis;
        position: relative;
    }

    &#mnn {
        display: block !important;
        line-height: 22px;
        height: 22px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 108px;
        text-overflow: ellipsis;
        position: relative;
    }
}

.custom-sale {
    width: fit-content;
}

.search-mobile {
    padding-top: Max(8px, calc(8px + constant(safe-area-inset-top)));
    padding-top: Max(8px, calc(8px + env(safe-area-inset-top)));
}

.search-field {
    width: 80%;

    @media screen and (max-width: 360px) {
        width: 75%;
    }

    @media screen and (min-width: 361px) and (max-width: 385px) {
        width: 78%;
    }
}

.search-result {
    margin-top: Max(68px, calc(60px + constant(safe-area-inset-top)));
    margin-top: Max(68px, calc(60px + env(safe-area-inset-top)));

    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}

.product {
    &__img {
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;
    }
}
</style>
