<template lang="pug">
.relative-position.logo-mobile(style="height: 32px;")
    img(src="logo.svg" style="width: 70px;" height="35px" alt="eTabl")
    .logo-text.relative-position(style="font-size: 8px; bottom: 4px; left: 4px;")
        | интернет-аптека
</template>

<script>
export default {
    name: 'LogoMobile',
};
</script>

<style lang="scss" scoped>
.logo-mobile {
    height: 32px;
}
.logo-text {
    font-size: 8px;
    font-weight: 700;
    bottom: 3px;
    left: 4px;
    color: $positive;
}
</style>
